import React, { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Colors from "../../../assets/styles";
import { Images } from "../../../assets/images";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header2() {
  // State for controlling the drawer open/close
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the current active route for underline
  const [activeButton, setActiveButton] = useState(location.pathname);

  // Function to handle button clicks and set active state
  const handleButtonClick = (path) => {
    setActiveButton(path);
    navigate(path);
  };

  // Toggle function to handle drawer open/close
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        sx={{ background: "none !important", boxShadow: "none !important" }}
      >
        <Container sx={{ mt: 1, maxWidth: "1300px !important" }}>
          <Toolbar sx={{paddingLeft:"0px !important",paddingRight:"0px !important"}}>
            <Grid container alignItems="center" justifyContent="space-between">
              {/* Logo Section */}
              <Grid
                item
                xs={6}
                sm={3}
                display="flex"
                justifyContent="flex-start"
                onClick={() => navigate("/")}
              >
                <img
                  src={Images.logo}
                  alt="Let's Eat Logo"
                  style={{ height: 50, width: 80, cursor: "pointer" }}
                />
              </Grid>

              {/* Main Navigation: Hidden on small screens */}
              <Grid
                item
                xs={0}
                sm={6}
                md={6}
                display={{ xs: "none", md: "flex" }}
              >
                <Box
                  sx={{ display: "flex", justifyContent: "center", gap: "7px" }}
                >
                  {/* Home Button with Default Active Underline */}
                  <Button
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      position: "relative",
                      fontSize: { md: "13px", sm: "11px", xs: "11px" },
                      textTransform: "capitalize",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        right: "50%",
                        width: activeButton === "/" ? "50%" : "0%",
                        height: "4px",
                        backgroundColor: Colors.primary,
                        transform: "translateX(-50%)",
                        transition: "width 0.3s ease-out",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                        "&::after": {
                          width: "50%",
                        },
                      },
                    }}
                    onClick={() => handleButtonClick("/")}
                  >
                    Home
                  </Button>

                  {/* Other Navigation Buttons */}
                  <Button
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      position: "relative",
                      fontSize: { md: "13px", sm: "11px", xs: "11px" },
                      textTransform: "capitalize",

                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        width: activeButton === "/home" ? "50%" : "0%",
                        height: "4px",
                        backgroundColor: Colors.primary,
                        transform: "translateX(-50%)",
                        transition: "width 0.3s ease-out",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                        "&::after": {
                          width: "50%",
                        },
                      },
                    }}
                    onClick={() => handleButtonClick("/home")}
                  >
                    Restaurants
                  </Button>
                  <Button
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      position: "relative",
                      fontSize: { md: "13px", sm: "11px", xs: "11px" },
                      textTransform: "capitalize",

                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        width:
                          activeButton === "/enquirydelivery" ? "50%" : "0%",
                        height: "4px",
                        backgroundColor: Colors.primary,
                        transform: "translateX(-50%)",
                        transition: "width 0.3s ease-out",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                        "&::after": {
                          width: "50%",
                        },
                      },
                    }}
                    onClick={() => handleButtonClick("/enquirydelivery")}
                  >
                    Partner with us
                  </Button>
                  <Button
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      position: "relative",
                      fontSize: { md: "13px", sm: "11px", xs: "11px" },
                      textTransform: "capitalize",

                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        width:
                          activeButton === "/retailenquirydelivery"
                            ? "50%"
                            : "0%",
                        height: "4px",
                        backgroundColor: Colors.primary,
                        transform: "translateX(-50%)",
                        transition: "width 0.3s ease-out",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                        "&::after": {
                          width: "50%",
                        },
                      },
                    }}
                    onClick={() => handleButtonClick("/retailenquirydelivery")}
                  >
                    Retail Partners
                  </Button>
                  <Button
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      position: "relative",
                      fontSize: { md: "13px", sm: "11px", xs: "11px" },
                      textTransform: "capitalize",

                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        width:
                          activeButton === "/privacy-policy" ? "50%" : "0%",
                        height: "4px",
                        backgroundColor: Colors.primary,
                        transform: "translateX(-50%)",
                        transition: "width 0.3s ease-out",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                        "&::after": {
                          width: "50%",
                        },
                      },
                    }}
                    onClick={() => handleButtonClick("/privacy-policy")}
                  >
                    Privacy policy
                  </Button>
                </Box>
              </Grid>

              {/* Sign In / Sign Up Buttons */}
              <Grid
                item
                xs={0}
                sm={3}
                display={{ xs: "none", md: "flex" }}
                justifyContent="flex-end"
              >
                <Button
                  color="inherit"
                  sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginLeft: 2,
                    textTransform: "capitalize",

                    backgroundImage:
                      "linear-gradient(450deg, #485461 0%, #000 50%, #000 100%)",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Sign up
                </Button>
              </Grid>

              {/* Drawer IconButton: Visible on small screens */}
              <Grid
                item
                xs={6}
                display={{ xs: "flex", md: "none" }}
                justifyContent="flex-end"
              >
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Drawer Component */}
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box
          sx={{ width: 350, padding: "16px", bgcolor: "black", height: "100%" }}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          {/* Centered Logo */}
          <Box display="flex" justifyContent="center" mb={3}>
            <img
              src={Images.logoWhite}
              alt="Let's Eat Logo"
              style={{ height: 70, width: 100 }}
            />
          </Box>

          {/* Navigation Links */}
          <List sx={{ textAlign: "center" }}>
            <ListItem button onClick={() => handleButtonClick("/")}>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color:
                        activeButton === "/" ? Colors.primary : Colors.white,
                      textAlign: "center",
                      fontSize:"15px",
                      ":hover": {
                        color: Colors.primary,
                      },
                      ":active": {
                        color: Colors.primary,
                      },
                      ":focus": {
                        color: Colors.primary,
                      },
                    }}
                  >
                    Home
                  </Typography>
                }
              />
            </ListItem>
            <ListItem button onClick={() => handleButtonClick("/home")}>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      textAlign: "center",
                      fontSize:"15px",
                      ":hover": {
                        color: Colors.primary,
                      },
                      ":active": {
                        color: Colors.primary,
                      },
                      ":focus": {
                        color: Colors.primary,
                      },
                    }}
                  >
                    Restaurants
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              button
              onClick={() => handleButtonClick("/enquirydelivery")}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color:
                        activeButton === "/enquirydelivery"
                          ? Colors.primary
                          : Colors.white,
                      textAlign: "center",
                      fontSize:"15px",
                      ":hover": {
                        color: Colors.primary,
                      },
                      ":active": {
                        color: Colors.primary,
                      },
                      ":focus": {
                        color: Colors.primary,
                      },
                    }}
                  >
                    Partner with us
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleButtonClick("/retailenquirydelivery")}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color:
                        activeButton === "/retailenquirydelivery"
                          ? Colors.primary
                          : Colors.white,
                      textAlign: "center",
                      fontSize:"15px",
                      ":hover": {
                        color: Colors.primary,
                      },
                      ":active": {
                        color: Colors.primary,
                      },
                      ":focus": {
                        color: Colors.primary,
                      },
                    }}
                  >
                    Retail Partners
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleButtonClick("/privacy-policy")}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      color:
                        activeButton === "/privacy-policy"
                          ? Colors.primary
                          : Colors.white,
                      textAlign: "center",
                      fontSize:"15px",
                      ":hover": {
                        color: Colors.primary,
                      },
                      ":active": {
                        color: Colors.primary,
                      },
                      ":focus": {
                        color: Colors.primary,
                      },
                    }}
                  >
                    Privacy policy
                  </Typography>
                }
              />
            </ListItem>
          </List>

          {/* Sign In / Sign Up Buttons */}
          <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
            <Button
              color="inherit"
              onClick={() => navigate("/login")}
              sx={{
                textTransform: "capitalize !important",
                fontWeight: "bold",
                marginBottom: 2,
                color: "white",
                fontSize:"15px",
                ":hover": {
                  color: Colors.primary,
                },
                ":active": {
                  color: Colors.primary,
                }, ":focus": {
                  color: Colors.primary,
                },
              }}
            >
              Sign in
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ 
                textTransform: "none",
                backgroundColor: Colors.primary,
                fontSize:"15px",
                width: "90%",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: Colors.primary,
                },
                ":active": {
                  color: Colors.primary,
                },
                ":focus": {
                  color: Colors.primary,
                },
              }}
              onClick={() => navigate("/login")}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
